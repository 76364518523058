var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-bottom": "20px" },
          attrs: {
            data: _vm.datas,
            border: "",
            "highlight-current-row": "",
            size: "mini",
            "header-cell-style": {
              "font-weight": "400",
              height: "40px",
              padding: "0",
              background: "#4a8bc1",
              color: "white"
            }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "HurtType",
              label: "测试项目",
              sortable: "",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.format.kneeHurtTypeFormat(scope.row.HurtType)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "Score",
              label: "得分或扣分项",
              sortable: "",
              width: "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "Question",
              label: "问题",
              sortable: "",
              "min-width": "150"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "Suggest",
              "show-overflow-tooltip": "",
              label: "建议",
              sortable: "",
              "min-width": "200"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "Creator.RealName",
              label: "创建人",
              sortable: "",
              width: "120"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "InsertTime",
              label: "创建时间",
              align: "center",
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.format.dateFormat(scope.row.InsertTime)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope.row)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "margin-top": "20px", "text-align": "right" },
        attrs: {
          background: "",
          "page-sizes": _vm.pageSizesList,
          "page-size": _vm.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
          "current-page": _vm.pageIndex
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
          "update:currentPage": function($event) {
            _vm.pageIndex = $event
          },
          "update:current-page": function($event) {
            _vm.pageIndex = $event
          }
        }
      }),
      _c("kneeHurtEditor", {
        ref: "kneeHurtEditor",
        on: { update: _vm.getPageData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }