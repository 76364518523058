<template>
  <div>
    <!--列表-->
    <el-table
      :data="datas"
      style="width: 100%; margin-bottom: 20px"
      border
      highlight-current-row
      size="mini"
      :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }"
    >
      <el-table-column prop="HurtType" label="测试项目" sortable width="150">
        <template slot-scope="scope">
            {{format.kneeHurtTypeFormat(scope.row.HurtType)}}
          </template>
      </el-table-column>
      <el-table-column prop="Score" label="得分或扣分项" sortable width="120">
      </el-table-column>
       <el-table-column prop="Question" label="问题" sortable min-width="150">
      </el-table-column>
      <el-table-column prop="Suggest" show-overflow-tooltip label="建议" sortable min-width="200">
      </el-table-column>
      <el-table-column
        prop="Creator.RealName"
        label="创建人"
        sortable
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="InsertTime"
        label="创建时间"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          {{ format.dateFormat(scope.row.InsertTime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleDelete(scope.row)"
            >删除</el-button
          >
          
          <el-button type="text" size="small" @click="handleEdit(scope.row)"
            >修改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px; text-align: right"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="pageSizesList"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :current-page.sync="pageIndex"
    ></el-pagination>
    <kneeHurtEditor
      ref="kneeHurtEditor"
      @update="getPageData"
    />
  </div>
</template>
<script>
import { getPageList, deleteObj } from "@/api/library/libKneeHurt";
import kneeHurtEditor from "./kneeHurtEditor";
export default {
  components: { kneeHurtEditor },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    filter: {
      handler(val) {
        this.pageIndex = 1;
        this.getPageData();
      },
    },
  },
  data() {
    return {
      datas: [],
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      pageSizesList: [10, 15, 20, 30, 50],
    };
  },

  methods: {
    getPageData() {
      let data = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        ...this.filter,
      };
      getPageList(data).then((response) => {
        if (response) {
          this.datas = response.Items;
          this.total = response.Total || response.Items.length;
        }
      });
    },
    handleSizeChange(val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getPageData());
    },
    handleCurrentChange(val) {
      this.pageIndex = `${val}`;
      this.getPageData();
    },
    //删除
    handleDelete: function (row) {
      this.$confirm("删除后影响报告输出，确认删除该记录吗?", "提示", {
        type: "warning",
      }).then(() => {
        let para = { key: row.Key };
        deleteObj(para).then((response) => {
          if (response) {
            this.msg.successMsg("操作成功");
            this.getPageData();
          }
        });
      });
    },
    handleCreate: function () {
      this.$refs.kneeHurtEditor.handleCreate();
    },
    handleEdit: function (row) {
      this.$refs.kneeHurtEditor.handleUpdate(row);
    },
    handleView: function (row) {
      this.$refs.kneeHurtEditor.handleView(row);
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.filter-disease-selector {
  /deep/.el-select {
    width: 150px !important;
  }
}
</style>